<template>
    <div class="carousel carousel-generic"
        :class="{
            'can-animate': (animatesInX) && isVisibleOnce,
            'animates-in-x': animatesInX
        }">
        <div class="swiper" :class="swiperClass" ref="swiperContainer">
            <SwiperWrapper>
                <slot></slot>
            </SwiperWrapper>
        </div>
        <div class="carousel-controls" :class="controlsClass">
            <button v-if="enableNavigation" ref="btnPrev" class="btn btn-nav btn-prev" :class="btnClass">
                <slot name="btn-icon-prev text-white">
                    <Icon symbol="arrow-left"></Icon>
                </slot>
            </button>
            <div v-if="enableBullets" ref="bullets" class="swiper-pagination-bullets"></div>
            <button v-if="enableNavigation" ref="btnNext" class="btn btn-nav btn-next" :class="btnClass">
                <slot name="btn-icon-next">
                    <Icon symbol="arrow-right"></Icon>
                </slot>
            </button>
            <div v-if="enableScrollbar" ref="scrollbar" class="swiper-scrollbar"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, PropType, ref } from "vue";
import { Icon } from '@/modules/core/components';
import SwiperWrapper from "./swiper-wrapper.vue";
import Swiper, { SwiperOptions, Navigation, Pagination, Autoplay, A11y, Grid, EffectFade, Scrollbar } from 'swiper';
import { useIntersectionObserver } from "@vueuse/core";

export default defineComponent({
    props: {
        enableNavigation: Boolean,
        enableBullets: Boolean,
        enableScrollbar: Boolean,
        animatesInX: Boolean,
        swiperClass: String,
        controlsClass: String,
        btnClass: String,
        config: {
            type: Object as PropType<SwiperOptions>,
            default: () => ({
                slidesPerView: 1,
                spaceBetween: 16,
                breakpoints: {
                    768: {
                        slidesPerView: 3
                    },
                    1200: {
                        slidesPerView: 4
                    }
                },
            })
        }
    },
    components: {
        SwiperWrapper,
        Icon,
    },
    setup (props) {
        let swiper: Swiper;
        const btnPrev = ref<HTMLElement>();
        const btnNext = ref<HTMLElement>();
        const bullets = ref<HTMLElement>();
        const scrollbar = ref<HTMLElement>();
        const swiperContainer = ref<HTMLElement>();
        const isVisibleOnce = ref(false);

        onMounted(() => {
            let config: SwiperOptions = JSON.parse(JSON.stringify(props.config));
            const modules = [A11y];
            if (props.enableNavigation) {
                config.navigation = {
                    prevEl: btnPrev.value,
                    nextEl: btnNext.value,
                }
                modules.push(Navigation);
            }
            if (props.enableBullets) {
                config.pagination = {
                    type: 'bullets',
                    el: bullets.value,
                    clickable: true
                }
                modules.push(Pagination)
            }
            if (props.enableScrollbar) {
                config.scrollbar = {
                    el: scrollbar.value,
                    draggable: true,
                    dragClass: 'swiper-scrollbar-drag',
                    dragSize: 200
                }
                modules.push(Scrollbar)
            }
            if (config.autoplay && typeof config.autoplay === 'object') {
                config.autoplay = {
                    ...config.autoplay,
                    pauseOnMouseEnter: true,
                    disableOnInteraction: false
                }
                modules.push(Autoplay)
            }
            if (config.grid) {
                modules.push(Grid);
            }
            if (config.effect == 'fade') {
                modules.push(EffectFade);
            }
            Swiper.use(modules);
            swiper = new Swiper(swiperContainer.value as HTMLElement, config)
        })

        const { stop } = useIntersectionObserver(
            swiperContainer,
            ([{ isIntersecting }]) => {
                if (isIntersecting) {
                    isVisibleOnce.value = true;
                    stop();
                }
            },
            { rootMargin: "0px 0px -10% 0px" }
        )

        onBeforeUnmount(() => {
            swiper.destroy()
            stop()
        })

        return {
            btnPrev,
            btnNext,
            bullets,
            scrollbar,
            swiperContainer,
            isVisibleOnce,
        }
    }
})
</script>

<style lang="scss">
@import "swiper/scss";
@import "swiper/scss/grid";
@import "swiper/scss/free-mode";
@import "swiper/scss/effect-fade";

.carousel-generic {
    position: relative;

    .swiper-scrollbar {
        background: #d6d6d6;
        margin-top: 2rem;
        width: 100%;
        overflow: hidden;

        &-drag {
            background: $secondary;
            border-radius: 999px;
            height: 5px;
            width: 300px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .swiper-pagination {
        &-bullets {
            padding: 1rem 0;
            display: flex;
            justify-content: center;
            gap: .5rem;
            justify-items: center;
        }

        &-lock {
            display: none;
        }

        &-bullet {
            background-color: rgba($black, .15);
            width: .5rem;
            height: .5rem;
            border-radius: 999px;
            transition: width .15s ease;
            will-change: width;

            &-active {
                background-color: var(--carousel-bullet-active-color, $secondary);
                width: 1rem;
            }
        }
    }

    .carousel-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .btn-nav {
            pointer-events: all;
            background-color: white;
            height: 1.5rem;
            width: 1.5rem;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            transition: .15s ease;
            transition-property: opacity, visibility;
            top: 50%;
            z-index: 1;
            box-shadow: $box-shadow;
            color: $text-muted;

            .icon {
                pointer-events: none;
            }

            &.swiper-button-disabled {
                visibility: hidden;
            }
        }
    }

    &.bullets-is-inset {
        .swiper-pagination-bullets {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 2rem;
            z-index: 1;
        }
    }

    &.bullets-is-outset {
        .swiper-pagination-bullets {
            position: absolute;
            left: 50%;
            bottom: -3rem;
            transform: translateX(-50%);
        }
    }

    &.nav-start {
        .carousel-controls {
            justify-content: flex-start;
        }
    }

    &.nav-end {
        .carousel-controls {
            justify-content: flex-end;
        }
    }

    &.nav-between {
        .carousel-controls {
            justify-content: space-between;
        }
    }

    &.slides-equal-height {
        .swiper-slide {
            height: auto;
        }

        .slide-content {
            height: 100%;
        }
    }

    @include media-breakpoint-up (md) {
        .carousel-controls {
            .btn-nav {
                height: 2rem;
                width: 2rem;

                .icon {
                    pointer-events: none;
                    height: 1.25rem;
                    width: 1.25rem;
                }
            }
        }

        @media (prefers-reduced-motion: no-preference) {
            &.animates-in-x {
                .swiper {
                    .swiper-slide {
                        opacity: 0;
                        transform: translateX(3rem);
                        transition: opacity .2s, transform .5s cubic-bezier(0,0,.22,1);
                        transition-delay: .3s;

                        @for $i from 1 through 6 {
                            &:nth-child(#{$i + 1}) {
                                transition-delay: (.3s + 0.05s * $i);
                            }
                        }
                    }
                }

                &.can-animate .swiper-initialized {
                    .swiper-slide {
                        opacity: 1;
                        transform: none;
                    }
                }
            }
        }
    }
}
</style>