<template>
    <div class="file-thumbnail rounded-2 ratio ratio-1x1 flex-shrink-0">
        <template v-if="isImage">
            <img :src="imageSrc" :alt="fileName">
        </template>
        <div v-else class="contents">
            <Icon symbol="file-earmark" class="icon-xxl"></Icon>
            <span class="extension">{{ extension }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount } from 'vue';
import { Icon } from '@/modules/core/components';
import { BaseMedia } from '@/modules/core/types';

interface Props {
    file: File|BaseMedia;
}

const props = defineProps<Props>();

const fileName = computed(() => {
    if (props.file instanceof File) {
        return props.file.name;
    }
    return props.file.url.split('/').reverse()[0];
})

const extension = computed(() => fileName.value.split('.').reverse()[0]);

const isImage = computed(() => {
    return /(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(extension.value)
})

const objectUrl = isImage.value && props.file instanceof File ? URL.createObjectURL(props.file) : undefined;

const imageSrc = computed(() => props.file instanceof File ? objectUrl : props.file.url);

onBeforeUnmount(() => {
    if (!objectUrl) return;
    URL.revokeObjectURL(objectUrl);
})
</script>

<style lang="scss">

.file-thumbnail {
    overflow: hidden;

    .contents {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            color: $gray-600;
        }

        .extension {
            position: absolute;
            background-color: tint-color($secondary, 80%);
            color: $secondary;
            padding: .125rem .5rem;
            border-radius: .5rem;
            font-size: .6rem;
            text-transform: uppercase;
            right: 0;
            bottom: 10%;
            font-weight: 600;
        }
    }
}
</style>