<template>
    <button ref="theButton" 
        @click="handleButtonClicked"
        :disabled="isFailInit || isLoading || disabled" 
        class="btn btn-sm btn-facebook-sign-in text-nowrap"
        type="button">
        <div class="hstack justify-content-between gap-2">
            <Icon class="icon-m" symbol="facebook-circular"></Icon>
            <span>{{ actualButtonText }}</span>
        </div>
    </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Icon } from '@/modules/core/components';

export default defineComponent({
    props: {
        text: {
            type: String as PropType<'Sign in'|'Sign up'|'Continue'>,
            default: 'Continue'
        },
        disabled: {
            type: Boolean
        }
    },
    computed: {
        actualButtonText(): string {
            if (this.isLoading) {
                return "Facebook sign up is loading...";
            }
            if (this.isFailInit) {
                return "Facebook sign up is not available";
            }
            if (this.facebookUser) {
                return "Continue as " + this.facebookUser.name;
            }
            return this.text + ' with Facebook';
        }
    },
    data() {
        return {
            isFailInit: false,
            isLoading: true,
            isLoggedIn: false,
            facebookUser: null as fb.User | null,
            existingAuthToken: ""
        };
    },
    emits: ['started', 'success'],
    methods: {
        handleButtonClicked(): void {
            this.$emit("started");
            if (!FB)
                return;
            if (this.existingAuthToken && this.facebookUser) {
                this.$emit("success", { token: this.existingAuthToken, name: this.facebookUser.name });
                return;
            }
            FB.login((response) => {
                if (response.authResponse) {
                    FB.api("/me", (profile: fb.User) => {
                        this.$emit("success", { token: response.authResponse.accessToken, name: profile.name });
                    });
                }
            }, { scope: "email,public_profile" });
        },
        initialize() {
            if (!window.FB) {
                this.isFailInit = true;
                this.isLoading = false;
                return;
            }
            FB.getLoginStatus((response) => {
                this.isLoading = false;
                if (response.status === "connected") {
                    this.isLoggedIn = true;
                    this.existingAuthToken = response.authResponse.accessToken;
                    FB.api("/me", profile => {
                        this.facebookUser = profile;
                    });
                }
            });
        }
    },
    created() {
        let fbJsScript = document.querySelector("#fbJs") as HTMLScriptElement;
        if (!fbJsScript) {
            fbJsScript = document.createElement("script");
            fbJsScript.src = "https://connect.facebook.net/en_US/sdk.js";
            fbJsScript.id = "fbJs";
            fbJsScript.async = true;
            fbJsScript.defer = true;
            fbJsScript.addEventListener("load", () => this.initialize(), { once: true });
            document.head.appendChild(fbJsScript);
        }
        else {
            this.initialize();
        }
    },
    components: { Icon }
})
</script>

<style lang="scss">
    .btn-facebook-sign-in {
        color: #1877f2;
        border-color: lighten($color: #1877f2, $amount: 30%);
        border-width: 1px;
        height: 40px;
        border-radius: 4px;

        &:hover {
            background-color: rgba(#1877f2, 0.1);
            border-color: #1877f2;
            color: #1877f2;
        }
    }
</style>