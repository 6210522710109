import { Slot } from "vue";

export const getSlotChildren = (slot: Slot) => {

    if (!slot) return [];



    const vNodes = slot();




    // If the contents of a slot is rendered using v-for, the Vue 3 compiler wraps

    // all generated elements into a Fragment element.

    if (vNodes.length === 1 && typeof vNodes[0].type === 'symbol') {

        return vNodes[0].children;

    }

    return vNodes;
}