<template>
    <div class="slider-wrapper d-flex align-items-center w-100 px-4">
        <div id="slider" ref="sliderRef"></div>
    </div>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from 'vue';
import noUiSlider, { API as noUiSliderInstance, Options as noUiSliderOptions } from 'nouislider';
import { formatAsCurrencyString } from '@/modules/core/utilities';
import { mergeTooltips } from './utilities';
import { omit } from 'lodash-es';

export default defineComponent({
    props: {
        minValue: Number,
        maxValue: Number,
		limitMin: {
            type: Number,
            required: true
        },
		limitMax: {
            type: Number,
            required: true
        },
		step: {
            type: Number,
            default: 1
        },
        formatter: {
            type: Function,
            default: (value: number) => formatAsCurrencyString(value)
        }
	},
    emits: [
        'update:minValue',
        'update:maxValue',
        'change'
    ],
    setup (props, { emit }) {
        const sliderRef = ref<HTMLElement|null>(null)
        noUiSlider.cssClasses.tooltip += ' caption';

        let instance: noUiSliderInstance;

        const getNoUiOptions = (): noUiSliderOptions => ({
            range: {
                min: props.limitMin,
                max: props.limitMax
            },
            tooltips: [true, true],
            behaviour: 'drag-tap',
            format: {
                to: value => props.formatter(value),
                from: value => Number(value)
            },
            step: props.step,
            connect: true,
            start: [props.limitMin, props.limitMax]
        })

        const emitUpdatedValues = () => {
            if (!instance) return;
            const [min, max] = instance.get(true) as [number, number];
            emit('update:minValue', Math.round(min))
            emit('update:maxValue', Math.round(max))
            emit('change')
        }

        const initialise = () => {
            const sliderEl = sliderRef.value;
            if (!sliderEl) return;

            let initialOptions = getNoUiOptions();
            initialOptions.start = [props.minValue ?? props.limitMin, props.maxValue ?? props.limitMax];
            instance = noUiSlider.create(sliderEl, initialOptions);

            let mergeTooltips$ = mergeTooltips(sliderEl, 30, ' - ');

            instance.on('change', () => {
                sliderEl.children[0].classList.add('grabbing');
                emitUpdatedValues()
            })

            instance.on('end', () => {
                sliderEl.children[0].classList.remove('grabbing');
            })

            const handleLimitsChange = () => {
                if (!instance) return;
                const options = getNoUiOptions();
                const start = options.start;
                instance.updateOptions(omit(options, 'start'), false)
                instance.set(start as any);
                emitUpdatedValues()
                mergeTooltips$()
                mergeTooltips$ = mergeTooltips(sliderEl, 30, ' - ')
            }

            const handleValuesChange = () => {
                if (!instance) return;
                const values = [props.minValue ?? props.limitMin, props.maxValue ?? props.limitMax];
                instance.set(values);
            }

            const limitWatcher = watch(
                () => ({ limitMin: props.limitMin, limitMax: props.limitMax}), 
                () => handleLimitsChange()
            )

            const valueWatcher = watch(
                () => ({ minValue: props.minValue, maxValue: props.maxValue}), 
                () => handleValuesChange()
            )

            onBeforeUnmount (() => {
                limitWatcher()
                valueWatcher()
            })
        }

        onMounted(() => initialise())

        onBeforeUnmount(() => {
            if (instance) {
                instance.destroy()
            }
        })

        return { sliderRef }
    }
})
</script>

<style lang="scss">
@import "nouislider/dist/nouislider.css";

.noUi-target {
    background: $gray-300;
    border: 0;
    height: 6px;
    width: 100%;

    .noUi-connects {
        border-radius: 4px;

        .noUi-connect {
            background-color: $secondary;
        }
    }

    .noUi-origin {
        .noUi-handle {
            cursor: pointer;
            background-color: white;
            width: 20px;
            height: 20px;
            top: -7px;
            border-radius: 50%;
            border: 6px solid $secondary;
            outline: 0;
            box-shadow: none;
            transition: .15s ease;
            transition-property: box-shadow;

            &::before,
            &::after {
                content: none;
            }

            &:focus {
                box-shadow: 0 0 0 $btn-focus-width rgba($secondary, 0.2);
            }
        }

        .noUi-tooltip {
            font-size: 12px;
            margin-bottom: 0.1rem;
            padding: 0.25rem 0.75rem;
            background-color: white;
            box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08),
                0px 30px 24px -10px rgba(154, 156, 165, 0.05),
                0px 12px 10px -6px rgba(154, 156, 165, 0.04),
                0px 4px 4px -4px rgba(30, 33, 44, 0.03);
            border-radius: 999px;
        }
    }
}

.slider-wrapper {
    // To offset the tooltip height only in mobile
    padding-top: 2.5rem;
    padding-bottom: .75rem;

    @include media-breakpoint-up (md) {
        padding-top: 0;
        padding-bottom: 0;
    }
}
</style>