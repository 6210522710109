<template>
    <div class="dropdown field" :class="{ 'is-active': isActive }">
        <label v-if="!floating && label" :id="labelId" :for="formControlId" class="dropdown-label mb-2">
            {{ label }}
        </label>
        <Menu :id="id" :value="isActive" bottom left>
            <template #activator>
                <div class="form-control-wrapper"
                    :class="{
                        'form-floating': floating,
                        'has-icon-start': $slots['icon-start']
                    }">
                    <div v-if="$slots['icon-start']" class="icon-start">
                        <slot name="icon-start"></slot>
                    </div>
                    <input 
                        class="form-control"
                        :class="{
                            'has-value': !!modelValue,
                            'is-invalid': isInvalid
                        }"
                        :id="formControlId"
                        type="search"
                        :placeholder="placeholder"
                        :value="getItemText(getSelectedItem())"
                        readonly
                        aria-haspopup="listbox"
                        autocomplete="off"
                        :aria-labelledby="[labelId, formControlId].join(' ')"
                        @click.prevent="showDropdown"
                        @focus="showDropdown"
                        @blur="handleBlur"
                        @keydown="handleKeyup"
                        :disabled="disabled">
                    <Icon symbol="chevron-down" class="icon-toggle"></Icon>
                    <label v-if="floating && label" :id="labelId" :for="formControlId" class="dropdown-label mb-2">
                        {{ label }}
                    </label>
                </div>
            </template>
            <ul class="v-menu-list"
                :id="listId"
                tabindex="-1"
                role="listbox"
                :aria-labelledby="labelId">
                <li v-if="allowDeselect"
                    @click="handleDeselect"
                    @mousedown="handleMouseDown"
                    class="list-item" 
                    :aria-selected="!modelValue ? 'true' : 'false'">
                    &nbsp;
                </li>
                <li v-for="(item, index) in items"
                    class="list-item"
                    :class="{
                        'is-selected': getItemValue(item) === modelValue,
                        'is-focused': listItemFocusIndex === index
                    }"
                    :aria-selected="getItemValue(item) === modelValue ? 'true' : 'false'"
                    @click="handleOptionClick(item)"
                    @mousedown="handleMouseDown">
                    {{ getItemText(item) }}
                </li>
            </ul>
        </Menu>
        <p v-show="errorMessage" class="error-message caption text-danger">{{ errorMessage }}</p>
    </div>
   
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Icon, Menu } from '@/modules/core/components';
import { ClickOutside } from "@/modules/core/directives";

export default defineComponent({
    props: {
        id: String,
        modelValue: {
            type: [Object, String, Number] as PropType<string|object|number>
        },
        label: String,
        placeholder: {
            type: String,
            default: 'Please Select'
        },
        items: Array as PropType<Array<string|object>>,
        itemText: String,
        itemValue: [String, Function],
        returnObject: Boolean,
        allowDeselect: Boolean,
        isInvalid: Boolean,
        errorMessage: String,
        floating: Boolean,
        disabled: Boolean
    },
    computed: {
        labelId() { return "label-" + this.id; },
        formControlId() { return "button-" + this.id; },
        listId() { return "list-" + this.id }
    },
    emits: ['update:modelValue', 'blur'],
    components: { Icon, Menu },
    data: () => ({
        isActive: false,
        listItemFocusIndex: -1
    }),
    methods: {
        getItemText (item?: string|object|number) {
            if (['string', 'number'].includes(typeof item)) return item;
            if (!item) return '';
            if (!this.itemText) return;
            return item[this.itemText as keyof typeof item];
        },
        getItemValue (item?: string|object|number) {
            if (typeof item === 'number') return item;
            if (!item) return '';
            if (typeof this.itemValue === 'function') {
                return this.itemValue(item)
            }
            if (typeof item === 'string') return item;
            if (!this.itemValue) return;
            return item[this.itemValue as keyof typeof item]
        },
        getSelectedItem () {
            if (typeof this.modelValue === undefined || this.modelValue === null) return '';
            const selectedItem = this.items?.find(x => this.getItemValue(x) === this.getItemValue(this.modelValue));
            return selectedItem ? selectedItem : '';
        },
        showDropdown () {
            this.isActive = true;
        },
        handleClickOutside () {
            this.isActive = false;
        },
        handleOptionClick (item: string|object) {
            const value = this.returnObject ? item : this.getItemValue(item);
            this.$emit('update:modelValue', value);
            this.$nextTick(() => {
                this.isActive = false;
                this.listItemFocusIndex = -1;
            })
        },
        handleBlur () {
            this.$emit('blur')
            setTimeout(() => {
                this.isActive = false;
                this.listItemFocusIndex = -1;
            }, 150)
        },
        handleKeyup (event: KeyboardEvent) {
            if (!this.items) return;
            switch (event.key) {
                case 'Enter':
                    event.preventDefault()
                    const item = this.items[this.listItemFocusIndex];
                    if (item) {
                        this.handleOptionClick(item)
                    }
                    return;
                case 'ArrowDown':
                    event.preventDefault();
                    if (!this.isActive) this.isActive = true;
                    this.listItemFocusIndex = Math.min(this.listItemFocusIndex + 1, this.items.length - 1);
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    if (!this.isActive) this.isActive = true;
                    this.listItemFocusIndex = Math.max(this.listItemFocusIndex - 1, 0);
                    break;
                case 'Escape':
                    this.listItemFocusIndex = -1;
                    this.isActive = false;
                    break;
            }
        },
        handleDeselect () {
            this.$emit('update:modelValue', undefined)
            this.$nextTick(() => {
                this.isActive = false;
                this.listItemFocusIndex = -1;
            })
        },
        handleMouseDown (e: Event) {
            e.preventDefault()
        }
    },
    directives: {
        ClickOutside
    },
})
</script>

<style lang="scss">

.dropdown {
    flex: 1 1 auto;

    .form-control {
        cursor: pointer;
        width: 100%;
        background-color: white;

        &:disabled, &.disabled {
            border: 0;
            background-color: #E9ECEF;
            cursor: default;
        }
    }

    .icon-start {
        position: absolute;
        left: .75rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .form-control-wrapper.has-icon-start {
        .form-control {
            padding-inline-start: 2.5rem;
        }
    }

    .icon-toggle {
        color: $secondary;
        transition: .3s ease;
        transition-property: transform, transform-origin;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
    }

    &.field-sm {
        .form-control {
            font-size: $input-font-size-sm;
            padding: $input-padding-y-sm $input-padding-x-sm;
        }

        .icon-toggle {
            width: .75rem;
            height: .75rem;
        }
    }

    &.is-active {
        .icon-toggle {
            transform: translateY(-50%) rotate(-180deg);
        }

        .form-control {
            &-wrapper::before {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
</style>