<template>
    <button class="btn btn-lg btn-outline-dark btn-apple-sign-in w-100 rounded-pill text-nowrap">
        <div class="hstack justify-content-between gap-2">
            <Icon class="icon-m" symbol="apple"></Icon>
            <span>{{ text }} with Apple</span>
        </div>
    </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Icon } from '@/modules/core/components';

export default defineComponent({
    components: { Icon },
    props: {
        text: {
            type: String as PropType<'Sign in'|'Sign up'|'Continue'>,
            default: 'Continue'
        }
    }
})
</script>

<style lang="scss">

.btn.btn-apple-sign-in {
    .icon {
        color: #000;
        transition: color .3s ease;
    }

    &:hover, &:active {
        color: #fff;

        .icon {
            color: #fff !important;
        }
    }
}

</style>