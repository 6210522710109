<template>
    <button
        @click="handleClicked"
        class="btn btn-sm btn-linkedin-sign-in text-nowrap"
        type="button">
        <div class="hstack justify-content-between gap-2 px-2">
            <Icon symbol="linkedin-fill" class="icon-m"></Icon>
            <span class="body-s">{{ text }} with LinkedIn</span>
        </div>
    </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Icon } from '@/modules/core/components';
import { openCenteredPopup } from "@/modules/core/utilities";
import { LinkedInAuthSuccessPayload } from "./types";
import { reactive } from "vue";
import appConstants from "@/modules/core/app.constants";

export default defineComponent({
    components: { Icon },
    props: {
        text: {
            type: String as PropType<'Sign in'|'Sign up'|'Continue'>,
            default: 'Continue'
        },
    },
    emits: ['success', 'fail', 'cancel'],
    setup (props, { emit }) {
        const url = appConstants.LINKED_IN_CALLBACK_URL;

        const state = reactive({
            isBusy: false,
            isSuccess: false
        });

        let popup: Window | null = null;

        const handleLinkedInAuthSuccess = (e: MessageEvent<string>) => {
            if (e.data) {
                const data = JSON.parse(e.data) as LinkedInAuthSuccessPayload;
                popup?.close();
                if (data.name === "linkedin.authSuccess") {
                    state.isSuccess = true;
                    emit('success', data.token)
                }
            }
        }

        const handleClicked = async () => {
            state.isSuccess = false;
            state.isBusy = true;
            popup = openCenteredPopup(url, props.text + " with LinkedIn", 450, 600)

            // The message event listener will fail to bind if the user is a new sign up
            // as LinkedIn does not allow cross-origin frames. Hence, we need to keep
            // polling until the user has been redirected back to our domain for us to 
            // get the auth token
            const interval = setInterval(async () => {
                if (popup) {
                    try {
                        popup.addEventListener("message", handleLinkedInAuthSuccess)
                        if (popup.closed) {
                            window.clearInterval(interval);

                            if (!state.isSuccess) {
                                emit('cancel')
                            }
                        }
                    } catch (error) {
                        console.error('LinkedIn Button:', error)
                    }
                }
            }, 200)
        }

        return {
            handleClicked
        }
    }
})
</script>

<style lang="scss">

.btn.btn-linkedin-sign-in {
    color: #0a66c2;
    border-color: lighten($color: #0a66c2, $amount: 40%);
    border-width: 1px;
    height: 40px;
    border-radius: 4px;

    &:hover {
        background-color: rgba(#0a66c2, 0.1);
        border-color: #0a66c2;
    }
}

</style>