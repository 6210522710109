<template>
    <div class="file-preview flex-wrap gap-2">
        <FileThumbnail v-if="file" class="icon icon-3xl" :file="file"></FileThumbnail>
        <div class="vstack justify-content-center">
            <p class="heading-s text-break mb-0">{{ fileName}}</p>
            <span v-if="fileSizeBytes" class="text-muted">{{ formatBytes(fileSizeBytes) }}</span>
        </div>
        <button v-if="!disabled" @click.prevent="$emit('delete')" class="btn btn-sm btn-overlay-danger d-inline-flex gap-2 has-initial-bg rounded-pill" type="button">
            <Icon symbol="trash-fill"></Icon>
            <span>Remove</span>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import FileThumbnail from './file-thumbnail.vue';
import { formatBytes } from '@/modules/core/utilities';
import { Icon } from '@/modules/core/components';
import { BaseMedia } from '@/modules/core/types';

export default defineComponent({
    props: {
        file: {
            type: Object as PropType<File|BaseMedia|null>,
            required: true
        },
        disabled: Boolean
    },
    components: { FileThumbnail, Icon },
    emits: ['delete'],
    setup (props) {
        const fileSizeBytes = props.file instanceof File ? props.file.size : 0;

        const fileName = props.file instanceof File ? props.file.name :
                         props.file?.url ? props.file.url.split('/').reverse()[0] : 
                         'Image' 

        return {
            formatBytes,
            fileSizeBytes,
            fileName
        }
    }
})
</script>

<style lang="scss">

.file-preview {
    display: flex;
    align-items: center;
    flex: 0 1 auto;

    .file-thumbnail {
        margin-right: 1rem;
    }
}
</style>