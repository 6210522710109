<template>
    <div class="share-icons" :class="{ [theme]: true }">
        <button @click.prevent="handleFacebookShare" 
            class="social-media-icon shadow">
            <Icon symbol="facebook"></Icon>
        </button>
        <button @click.prevent="handleTwitterShare"
            class="social-media-icon shadow">
            <Icon symbol="twitter"></Icon>
        </button>
        <button @click.prevent="handleTelegramShare" class="social-media-icon shadow">
            <Icon symbol="telegram"></Icon>
        </button>
        <a :href="whatsAppUrl" target="_blank" class="social-media-icon shadow">
            <Icon symbol="whatsapp"></Icon>
        </a>
        <a v-if="isNativeShareSupported" 
            class="social-media-icon shadow"
            @click.prevent="handleNativeShare">
            <Icon symbol="share-2"></Icon>
        </a>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { Icon } from '@/modules/core/components';
import { UseShareOptions, useShare } from "@vueuse/core";
import { openCenteredPopup, getFacebookSharerUrl, getTelegramShareUrl, getTwitterShareUrl, getWhatsAppShareUrl } from "@/modules/core/utilities";

export default defineComponent({
    components: { Icon },
    props: {
        theme: {
            type: String,
            default: 'on-light'
        },
        shareTitle: String,
        shareUrl: String
    },
    setup (props) {
        const title = props.shareTitle ?? document.title;
        const url = props.shareUrl ?? window.location.href;

        const { isSupported, share } = useShare({ title, url });

        const handleNativeShare = () => {
            share()
        }

        const handleFacebookShare = () => {
            openCenteredPopup(getFacebookSharerUrl(url), 'Share this on Facebook', 450, 600);
        }

        const handleTwitterShare = () => {
            openCenteredPopup(getTwitterShareUrl(url, title), 'Share this on Twitter', 450, 600);
        }

        const handleTelegramShare = () => {
            openCenteredPopup(getTelegramShareUrl(url, title), 'Share this on Telegram', 450, 600);
        }

        const whatsAppUrl = computed(() => getWhatsAppShareUrl(url));

        return {
            isNativeShareSupported: isSupported,
            handleNativeShare,
            handleFacebookShare,
            handleTwitterShare,
            handleTelegramShare,
            whatsAppUrl
        }
    }
})
</script>

<style lang="scss">

.share-icons {
    display: flex;
    align-items: center;
    gap: .25rem;

    .icon {
        color: $gray-700;
        transition: color .15s ease;
    }

    .social-media-icon {
        border-radius: 100%;
        width: 2rem;
        height: 2rem;
        background-color: rgba(255,255,255,0.2);
        appearance: none;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        transition: background-color .15s ease;
        
        &:hover, &:focus {
            background-color:white;
            
            .icon {
                color: $secondary;
            }
        }
    }

    &.on-dark {
        .icon {
            color: white;
        }
    }
}
</style>