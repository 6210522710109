<template>
    <div class="v-menu-outer" v-click-outside="handleClickoutside">
        <div ref="activatorRef" class="activator-wrapper">
            <slot name="activator" :on="activatorOn"></slot>
        </div>
        <div ref="menuRef" class="v-menu" :class="{ 'is-active': isActive }">
            <div class="v-menu-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from "vue";
import { createPopper, Instance as PopperInstance, Placement, Options } from '@popperjs/core';
import { ClickOutside } from "@/modules/core/directives";

/**
 * Inspired by Vuetify's v-menu
 */
export default defineComponent({
    props: {
        offsetY: Number,
        offsetX: Number,
        top: Boolean,
        bottom: Boolean,
        left: Boolean,
        right: Boolean,
        value: Boolean,
        disableGpuAcceleration: Boolean,
        fixedStrategy: Boolean
    },
    directives: {
        ClickOutside
    },
    setup (props) {
        const state = reactive({
            isActive: !!props.value
        })
        const menuRef = ref<HTMLElement|null>(null);
        const activatorRef = ref<HTMLElement|null>(null);
        let popperInstance: PopperInstance|null = null;

        const getPlacement = (): Placement => {
            let vPlacement = 'bottom';
            let hPlacement = '';
            if (props.top) {
                vPlacement = 'top';
            }
            if (props.bottom) {
                vPlacement = 'bottom';
            }
            if (props.left) {
                hPlacement = 'start';
            }
            if (props.right) {
                hPlacement = 'end';
            }
            return [vPlacement, hPlacement].filter(Boolean).join('-') as any
        }

        onMounted(() => {
            let activator = activatorRef.value;
            let menu = menuRef.value;

            if (activator && menu) {
                const popperConfig: Partial<Options> = {
                    placement: getPlacement(),
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                flipVariations: false
                            }
                        }
                    ],
                    strategy: props.fixedStrategy ? 'fixed' : 'absolute'
                }
                if (props.disableGpuAcceleration) {
                    popperConfig.modifiers?.push({
                        name: 'computeStyles',
                        options: {
                            gpuAcceleration: false
                        },
                    })
                }
                if (props.offsetY || props.offsetX) {
                    popperConfig.modifiers?.push({
                        name: 'offset',
                        options: {
                            offset: [props.offsetX, props.offsetY]
                        }
                    })
                }
                popperInstance = createPopper(activator as HTMLElement, menu, popperConfig)

                watch(() => props.value, (newValue) => state.isActive = newValue)

                // Check if the menu is currently inside a modal or offcanvas
                const parentModalOrOffcanvas = activator.closest('.modal, .offcanvas');

                if (parentModalOrOffcanvas) {
                    const bsComponentType = parentModalOrOffcanvas.classList.contains('modal') ? 'modal' : 'offcanvas';
                    const bsShownEvent = 'shown.bs.' + bsComponentType;
                    parentModalOrOffcanvas.addEventListener(bsShownEvent, () => {
                        popperInstance?.update()
                    })
                }
            }

            onBeforeUnmount(() => {
                if (popperInstance) {
                    popperInstance.destroy()
                }
            })
        })

        const activatorOn = {
            click: () => {
                state.isActive = !state.isActive;
            }
        }

        const handleClickoutside = () => state.isActive = false;

        return {
            ...toRefs(state),
            menuRef,
            activatorOn,
            activatorRef,
            handleClickoutside
        }
    }
})
</script>

<style lang="scss">
$main-color-light: scale-color($color: $secondary, $lightness: 90%);

.v-menu-outer {
    display: flex;

    .activator-wrapper {
        width: 100%;
    }
}

.v-menu {
    z-index: $zindex-dropdown;
    padding-top: .25rem;
    backface-visibility: hidden;
    pointer-events: none;

    .v-menu-content {
        border-radius: $input-border-radius;
        max-width: 100%;
        min-width: 200px;
        overflow: hidden;
        transition: transform .15s ease;
        transform: scale(0.98);
        will-change: transform;
        visibility: hidden;
        background-color: #fff;
        box-shadow: 0 4px 8px rgba(black, .3);
    }

    &.is-active {
        .v-menu-content {
            visibility: visible;
            transform: none;
            pointer-events: all;
        }
    }

    @include media-breakpoint-up (md) {
        .v-menu-content {
            max-width: 300px;
        }
    }
}

.v-menu-list {
    overflow-y: auto;
    list-style: none;
    scrollbar-width: thin;
    max-height: 18rem;
    padding: 0.25rem 0;
    overscroll-behavior: contain;
    scrollbar-color: $secondary;
    padding-left: 0;
    margin-bottom: 0;

    &::-webkit-scrollbar {
        width: 3px;
        background-color: $gray-100;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $secondary;
    }

    .list-item {
        padding: $input-padding-y $input-padding-x;
        font-size: 1rem;
        color: $input-color;
        cursor: pointer;
        transition: .15s ease;
        transition-property: color, background-color;

        &-text {
            display: inline-block;
            min-height: 1rem;
        }

        &:hover {
            background-color: $gray-100;
        }

        &.is-selected {
            color: $secondary;
            background-color: rgba($secondary, .05);
        }

        &.is-focused {
            background-color: $main-color-light;
        }
    }
}

</style>