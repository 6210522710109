/**
 * @param slider HtmlElement with an initialized slider
 * @param threshold Minimum proximity (in percentages) to merge tooltips
 * @param separator String joining tooltips
 */
export function mergeTooltips(slider: HTMLElement, threshold: number, separator: string) {
    const textIsRtl = getComputedStyle(slider).direction === 'rtl';
    const isRtl = slider.noUiSlider.options.direction === 'rtl';
    const isVertical = slider.noUiSlider.options.orientation === 'vertical';
    const tooltips = slider.noUiSlider.getTooltips();
    const origins = slider.noUiSlider.getOrigins();

    // Move tooltips into the origin element. The default stylesheet handles this.
    for (const handleNumber in tooltips) {
        const tooltip = tooltips[handleNumber]; 
        if (tooltip) {
            origins[handleNumber].appendChild(tooltip);
        }
    }

    slider.noUiSlider.on('update', function (values, handle, unencoded, tap, positions) {

        const pools: Array<Array<number>> = [[]];
        const poolPositions: Array<Array<number>> = [[]];
        const poolValues: Array<Array<number|string>> = [[]];
        let atPool = 0;

        // Assign the first tooltip to the first pool, if the tooltip is configured
        if (tooltips[0]) {
            pools[0][0] = 0;
            poolPositions[0][0] = positions[0];
            poolValues[0][0] = values[0];
        }

        for (let i = 1; i < positions.length; i++) {
            if (!tooltips[i] || (positions[i] - positions[i - 1]) > threshold) {
                atPool++;
                pools[atPool] = [];
                poolValues[atPool] = [];
                poolPositions[atPool] = [];
            }

            if (tooltips[i]) {
                pools[atPool].push(i);
                poolValues[atPool].push(values[i]);
                poolPositions[atPool].push(positions[i]);
            }
        }

        pools.forEach((pool, poolIndex) => {
            const handlesInPool = pool.length;

            for (let j = 0; j < handlesInPool; j++) {
                const handleNumber = pool[j];

                if (j === handlesInPool - 1) {
                    let offset = 0;

                    poolPositions[poolIndex].forEach((value) => offset += 1000 - value);

                    const direction: keyof CSSStyleDeclaration = isVertical ? 'bottom' : 'right';
                    const last = isRtl ? 0 : handlesInPool - 1;
                    const lastOffset = 1000 - poolPositions[poolIndex][last];
                    offset = (textIsRtl && !isVertical ? 100 : 0) + (offset / handlesInPool) - lastOffset;
                    
                    const currTooltip = tooltips[handleNumber];
                    if (currTooltip) {
                        // Center this tooltip over the affected handles
                        currTooltip.innerHTML = poolValues[poolIndex].join(separator);
                        currTooltip.style.display = 'block';
                        currTooltip.style[direction] = offset + '%';
                    }
                } else {
                    const currTooltip = tooltips[handleNumber];
                    if (currTooltip) {
                        // Hide this tooltip
                        currTooltip.style.display = 'none';
                    }
                }
            }
        });
    });

    return function () {
        slider.noUiSlider.off('update');
    }
}