<script lang="ts">
import { defineComponent, h, VNodeArrayChildren, isVNode, VNodeNormalizedChildren } from "vue";
import { getSlotChildren } from "../../utilities";

export default defineComponent({
    render () {
        let newChildren: VNodeArrayChildren = [];
        if (this.$slots.default) {
            newChildren = getSlotChildren(this.$slots.default) as VNodeArrayChildren[];
        }
        return h('div', { class: 'swiper-wrapper' }, newChildren)
    }
})
</script>