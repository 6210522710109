export function getFacebookSharerUrl (url: string) {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`
}

export function getTwitterShareUrl (url: string, text?: string) {
    const shareUrl = new URL(`https://twitter.com/intent/tweet`);
    shareUrl.searchParams.set('url', url);
    if (text) {
        shareUrl.searchParams.set('text', text);
    }
    return shareUrl.toString()
}

export function getTelegramShareUrl (url: string, text?: string) {
    const shareUrl = new URL('https://telegram.me/share/url');
    shareUrl.searchParams.set('url', url);
    if (text) {
        shareUrl.searchParams.set('text', text);
    }
    return shareUrl.toString()
}

export function getWhatsAppShareUrl (text: string) {
    const shareUrl = new URL('https://wa.me');
    shareUrl.searchParams.set('text', text);
    return shareUrl.toString()
}